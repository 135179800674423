import RcDomainObject from "../../common/RcDomainObject";

import CheckListMap from "../checklist/CheckListMap.js";
import Company from "../company/Company.js";
import ImageMap from "../image/ImageMap.js";
import EquipmentMap from "./EquipmentMap.js";
import RcDate from "@/domain/session/time/RcDate.js";

export default class Equipment extends RcDomainObject {

  static MODEL_NAME = "Equipment";

  static FIELDS = {
    NAME: "name",
    CHECKLIST_MAP: CheckListMap.MODEL_NAME,
    START_DATE: "serviceStartDate",
    END_DATE: "serviceEndDate",
    IMAGES: ImageMap.MODEL_NAME,
    COMPANY: Company.MODEL_NAME,
    DEFECT_TYPE: "defectType",
    SUMMARY: 'summary',
    SERIAL_NUMBER: 'vin',
    MAKE: 'make',
    MODEL: 'model',
    LAST_SAFETY_DATE: 'cvorDate',
    ANNUAL_NUMBER: 'cvorNumber',
    YEAR: 'year',
    COLOUR: 'colour',
  }

  constructor(domain, data) {
    super(domain, data, Equipment.MODEL_NAME);
  }
  
  withLastSafetyDate(date) {
    this.put(Equipment.FIELDS.LAST_SAFETY_DATE, date);
    return this;
  }
  
  lastSafetyDate() {
    var dateValue = this.getString(Equipment.FIELDS.LAST_SAFETY_DATE);
    var timeZone = this.timeZone();
    var rcDate = new RcDate(new Date(parseInt(dateValue)), timeZone);
    return rcDate;
  }

  serialNumber() {
    return this.getString(Equipment.FIELDS.SERIAL_NUMBER);
  }

  summary() {
    return this.getString(Equipment.FIELDS.SUMMARY);
  }
  
  colour() {
    return this.getString(Equipment.FIELDS.COLOUR);
  }

  make() {
    return this.getString(Equipment.FIELDS.MAKE);
  }
  
  model() {
    return this.getString(Equipment.FIELDS.MODEL);
  }
  
  name() {
    return this.getString(Equipment.Fields.NAME);
  }

  withName(name) {
    this.put(Equipment.Fields.NAME, name);
    return this;
  }

  company() {
    var value = this.get(Equipment.Fields.COMPANY);
    return new Company(this.domain, value);
  }

  withCompany(value) {
    this.put(Equipment.Fields.COMPANY, value);
    return this;
  }

  defectType() {
    return this.getString(Equipment.Fields.DEFECT_TYPE);
  }

  withDefectType(value) {
    this.put(Equipment.Fields.DEFECT_TYPE, value);
    return this;
  }

  checklists() {
    var value = this.get(Equipment.Fields.CHECKLIST_MAP);
    return new CheckListMap(this.domain, value);
  }

  withChecklists(checklists) {
    this.put(Equipment.Fields.CHECKLIST_MAP, checklists);
    return this;
  }

  withImages(imageMap) {
    this.put(Equipment.Fields.IMAGES, imageMap);
    return this;
  }

  images() {
    return new ImageMap(this.domain, this.get(Equipment.Fields.IMAGES))
  }

  withStartDate(date) {
    this.put(Equipment.FIELDS.START_DATE, date);
    return this;
  }

  startDate() {
    return this.getString(Equipment.Fields.START_DATE);
  }

  withEndDate(date) {
    this.put(Equipment.Fields.END_DATE, date);
    return this;
  }

  endDate() {
    return this.getString(Equipment.Fields.END_DATE);
  }

  map() {
    const map = new EquipmentMap(this.domain);
    map.addEquipment(this);
    return map;
  }
}