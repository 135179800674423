import RcDomainMap from "../../common/RcDomainMap";
import Equipment from "./Equipment";
import EquipmentUtils from "./EquipmentUtils";

export default class EquipmentMap extends RcDomainMap {
  
  static MODEL_NAME = "EquipmentMap";
  
  constructor(domain, data) {
    super(domain, data, EquipmentMap.MODEL_NAME);
  }

  copy() {
    var data = this._copy();
    return new EquipmentMap(this.domain, data);
  }

  find() {
    var keys = this.keys();
    var found = new EquipmentMap(this.domain, {} );
    for (var index = 0; index < keys.length; index++) {
      var key = keys[index];
      var data = this.domain.equipment().findById(key);
      var item = new Equipment(this.domain, data);
      if (!item.isNew()) {
        found.add(item);
      }
    }
    return found;
  }
  
  list() {
    var values = [];
    var keys = this.keys();
    for (var index = 0; index < keys.length; index++) {
      var key = keys[index];
      var item = this.getEquipment(key);
      values.push(item);
    }
    return values;
  }
  
  sort() {
    var list = this.list();
    return list.sort(EquipmentUtils.SortByCreatedDate);
  }
  
  sortByName() {
    var list = this.list();
    return list.sort(EquipmentUtils.SortByName);
  }

  first() {
    var all = this.list();
    if (all.length > 0) {
      return all[0];
    }
    return new Equipment(this.domain);
  }
  
  getEquipment(key) {
    var value = super.get(key);
    return new Equipment(this.domain, value);
  }

  addEquipment(equipment) {
    this.add(equipment).done();
    return this;
  }
}