
import RcDomainMap from "../../common/RcDomainMap.js";
import Asset from './Asset.js';

export default class AssetMap extends RcDomainMap {
  static MODEL_NAME = Asset.MODEL_NAME  + "Map";
  
  constructor(domain, data) {
    super(domain, data, AssetMap.MODEL_NAME);
  }

  copy() {
    var data = this._copy();
    return new AssetMap(this.domain, data);
  }
  
  priceForTimesheets() {
    var keys = this.keys();
    for (var index = 0; index < keys.length; index++) {
      var key = keys[index];
      var asset = new Asset(this.domain, this.get(key));
      var feature = asset.feature().find();
      if (feature.isTimesheets()) {
        var featurePrice = feature.price();
        var discount = asset.discount();
        var price = parseFloat(featurePrice) * parseFloat(discount);
        return featurePrice - price;
      }
    }
    return 0;
  }
  
  priceForInspections() {
    var keys = this.keys();
    for (var index = 0; index < keys.length; index++) {
      var key = keys[index];
      var asset = new Asset(this.domain, this.get(key));
      var feature = asset.feature().find();
      if (feature.isInspections()) {
        var featurePrice = feature.price();
        var discount = asset.discount();
        var price = parseFloat(featurePrice) * parseFloat(discount);
        return featurePrice - price;
      }
    }
    return 0;
  }
  
  priceForVehicles() {
    var keys = this.keys();
    for (var index = 0; index < keys.length; index++) {
      var key = keys[index];
      var asset = new Asset(this.domain, this.get(key));
      var feature = asset.feature().find();
      if (feature.isVehicles()) {
        var featurePrice = feature.price();
        var discount = asset.discount();
        var price = parseFloat(featurePrice) * parseFloat(discount);
        return featurePrice - price;
      }
    }
    return 0;
  }
  
  priceForEquipmentInspections() {
    var keys = this.keys();
    for (var index = 0; index < keys.length; index++) {
      var key = keys[index];
      var asset = new Asset(this.domain, this.get(key));
      var feature = asset.feature().find();
      if (feature.isEquipmentInspections()) {
        var featurePrice = feature.price();
        var discount = asset.discount();
        var price = parseFloat(featurePrice) * parseFloat(discount);
        return featurePrice - price;
      }
    }
    return 0;
  }

  priceForEquipment() {
    var keys = this.keys();
    for (var index = 0; index < keys.length; index++) {
      var key = keys[index];
      var asset = new Asset(this.domain, this.get(key));
      var feature = asset.feature().find();
      if (feature.isEquipment()) {
        var featurePrice = feature.price();
        var discount = asset.discount();
        var price = parseFloat(featurePrice) * parseFloat(discount);
        return featurePrice - price;
      }
    }
    return 0;
  }

  find() {
    var keys = this.keys();
    var found = new AssetMap(this.domain, {} );
    for (var index = 0; index < keys.length; index++) {
      var key = keys[index];
      var data = this.domain.assets().findById(key);
      var item = new Asset(this.domain, data);
      if (!item.isNew()) {
        found.add(item);
      }
    }
    return found;
  }
  
}