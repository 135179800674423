
import RcDomainObject from "@/domain/common/RcDomainObject";
import Feature from "./Feature";

export default class FeaturesDomain extends RcDomainObject {
  
  constructor(domain, storage) {
    super(domain);
    this.storage = storage;
  }
  
  _list() {
    return this.storage.list;
  }
  
  findById(objId) {
    var objects = this._list();
    for (var i = 0; i < objects.length; i++) {
      var item = objects[i];
      if (super.toId(item) == objId) {
        return new Feature(this.domain, item);
      }
    }

    return new Feature(this.domain, {});
  }
  
  inspections() {
    var objects = this._list();
    for (var i = 0; i < objects.length; i++) {
      var item = objects[i];
      var f = new Feature(this.domain, item);
      if (f.isInspections()) {
        return f;
      }
    }
    return new Feature(this.domain);
  }
  
  vehicles() {
    var objects = this._list();
    for (var i = 0; i < objects.length; i++) {
      var item = objects[i];
      var f = new Feature(this.domain, item);
      if (f.isVehicles()) {
        return f;
      }
    }
    return new Feature(this.domain);
  }
  
  users() {
    var objects = this._list();
    for (var i = 0; i < objects.length; i++) {
      var item = objects[i];
      var f = new Feature(this.domain, item);
      if (f.isUsers()) {
        return f;
      }
    }
    return new Feature(this.domain);
  }
  
  sso() {
    var objects = this._list();
    for (var i = 0; i < objects.length; i++) {
      var item = objects[i];
      var f = new Feature(this.domain, item);
      if (f.isSso()) {
        return f;
      }
    }
    return new Feature(this.domain);
  }
  
  checkins() {
    var objects = this._list();
    for (var i = 0; i < objects.length; i++) {
      var item = objects[i];
      var f = new Feature(this.domain, item);
      if (f.isCheckIns()) {
        return f;
      }
    }
    return new Feature(this.domain);
  }
  
  timesheets() {
    var objects = this._list();
    for (var i = 0; i < objects.length; i++) {
      var item = objects[i];
      var f = new Feature(this.domain, item);
      if (f.isTimesheets()) {
        return f;
      }
    }
    return new Feature(this.domain);
  }
  
  messages() {
    var objects = this._list();
    for (var i = 0; i < objects.length; i++) {
      var item = objects[i];
      var f = new Feature(this.domain, item);
      if (f.isMessages()) {
        return f;
      }
    }
    return new Feature(this.domain);
  }
  
  home() {
    var objects = this._list();
    for (var i = 0; i < objects.length; i++) {
      var item = objects[i];
      var f = new Feature(this.domain, item);
      if (f.isHome()) {
        return f;
      }
    }
    return new Feature(this.domain);
  }
  
  compliance() {
    var objects = this._list();
    for (var i = 0; i < objects.length; i++) {
      var item = objects[i];
      var f = new Feature(this.domain, item);
      if (f.isCompliance()) {
        return f;
      }
    }
    return new Feature(this.domain);
  }
  
  equipment() {
    var objects = this._list();
    for (var i = 0; i < objects.length; i++) {
      var item = objects[i];
      var f = new Feature(this.domain, item);
      if (f.isEquipment()) {
        return f;
      }
    }
    return new Feature(this.domain);
  }

  equipmentInspections() {
    var objects = this._list();
    for (var i = 0; i < objects.length; i++) {
      var item = objects[i];
      var f = new Feature(this.domain, item);
      if (f.isEquipmentInspections()) {
        return f;
      }
    }
    return new Feature(this.domain);
  }

  profileUpdate() {
    var objects = this._list();
    for (var i = 0; i < objects.length; i++) {
      var item = objects[i];
      var f = new Feature(this.domain, item);
      if (f.isProfileUpdate()) {
        return f;
      }
    }
    return new Feature(this.domain);
  }
  
  checklists() {
    var objects = this._list();
    for (var i = 0; i < objects.length; i++) {
      var item = objects[i];
      var f = new Feature(this.domain, item);
      if (f.isCheckLists()) {
        return f;
      }
    }
    return new Feature(this.domain);
  }
}